.section {
  display: flex;
  /* background-color: rgba(255, 255, 255, 0.598); */
  color: white;
  box-sizing: border-box;
  flex-direction: column;

  /* backdrop-filter: blur(10px); */
}

.sectionContainer {
  display: grid;
  grid-template-columns: min-content min-content 1fr;
  align-items: start; /* This will align the items to the bottom of the grid area */
  width: 100%;
  flex-grow: 1;
}
.sectionChildren {
  flex-grow: 1;
  padding: 0px;
  box-sizing: border-box;
  height: 100%;
}

.textBox {
  color: rgb(255, 255, 255);
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.342);
  font-size: 35px;
  font-weight: 800;
  display: flex;
  position: relative;
  bottom: 0;
  writing-mode: vertical-lr;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
}

.textStyleContainer {
  display: flex;
  position: absolute;
}

.textStyling {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60%;
  object-fit: fill;
}

.line {
  width: 3px;
  margin-right: 30px;
  overflow: hidden;
  height: 100%;
  background-color: rgb(255, 255, 255);
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.342);
}

@media screen and (max-width: 1100px) {
  .section {
    padding: 0px;
  }

  .sectionContainer {
    gap: 15px;
  }

  .textBox {
    font-size: 28px;
    line-height: 17px;
  }

  .line {
    margin-right: 15px;
  }
}

@media screen and (max-width: 768px) {
  .section {
    gap: 1px;
    padding: 0px;
  }

  .textBox {
    font-size: 18px;
    line-height: 8px;
  }

  .line {
    margin-right: 0px;
  }
}

@media (prefers-color-scheme: dark) {
  .section {
    /* background-color: rgba(0, 0, 0, 0.549); */
    color: white;
  }

  .line {
    background-color: white;
  }
}
