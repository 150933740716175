.header {
  color: rgb(17, 17, 17);
  font-size: xx-large;
  font-weight: bolder;
  filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.1));
  padding: 15px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 3px;
  /* text-decoration: underline; */
}

.megaGrid {
  display: flex;
  align-items: center;
  gap: 30px;
}

@media screen and (max-width: 768px) {
  .header {
    font-size: large;
    padding: 10px;
  }
}
