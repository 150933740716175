.six-box {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: none;
}
.six-box:before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.six-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  text-align: center;
}
