@import url("https://fonts.googleapis.com/css?family=Proxima+Nova");

body {
  margin: 0;
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

a {
  color: white;
  height: min-content;
  text-decoration: underline dotted;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background {
  position: fixed;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(8px);
}

.backgroundAnimation {
  z-index: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background: radial-gradient(
    circle at center,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 10%,
    rgba(255, 254, 234, 1) 13%,
    #c2e6e8 24%,
    #b7e8eb 100%
  );
}

.ocean {
  height: 5%;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #015871;
}

.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg)
    repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 15s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 22s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 17s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

@media (prefers-color-scheme: dark) {
  body,
  .backgroundAnimation {
    background: radial-gradient(
      circle at center,
      rgb(255, 255, 255) 0%,
      rgb(255, 255, 255) 8%,
      rgb(229, 229, 229) 15%,
      rgb(0, 53, 102) 70%,
      rgb(0, 33, 46) 100%
    );
    color: white;
  }
}
