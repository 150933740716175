.App {
  position: relative;
  overflow-x: auto;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 0;
  background-color: transparent;
  color: black;
  text-align: center;
}

.scrollContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.contentMain {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  box-sizing: border-box;
  max-width: 1200px;
  margin-bottom: 100px;
}

.Sections {
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;
  gap: 50px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.sectionGrid {
  display: flex;
  gap: 20px;
}

.teamMembers {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  gap: 30px;
  justify-content: space-evenly;
  transition: 300ms ease-in-out;
}

.headerContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-around;
  flex-grow: 1;
  overflow: hidden;
  box-sizing: border-box;
  /* background-color: rgba(255, 255, 255, 0.711); */
  backdrop-filter: blur(10px);
  padding: 5px;
}

.App-logo {
  pointer-events: none;
  height: 40vmin;
}

.logo {
  height: 70px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.342));
}
.video {
  width: 100%;
}

.backgroundImage {
  object-fit: cover;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
}

@media screen and (max-width: 1100px) {
  .App {
  }
  .contentMain {
  }
  .Sections {
    padding: 20px;
  }
  .headerContainer {
  }
  .logo {
  }
}

/* Media Query for screen width under 600px */
@media screen and (max-width: 768px) {
  .App {
  }

  .header {
    font-size: 20px;
  }

  .contentMain {
    max-width: 100%;
  }
  .Sections {
    grid-template-columns: 1fr;
    padding: 10px;
    gap: 8vw;
  }
  .headerContainer {
  }
  .logo {
    height: 45px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
